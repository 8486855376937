import BumpsLocationTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/bumps-location/BumpsLocationTemplate';

import { BUMPS_FACE_LOCATIONS, GENDER } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinTexture/BumpsLocation/BumpsLocationTemplate'
};

const createStory = props => () => ({
  components: { BumpsLocationTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <bumps-location-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  comedoLocations: [BUMPS_FACE_LOCATIONS.NOSE, BUMPS_FACE_LOCATIONS.FOREHEAD],
  gender: GENDER.MALE
});
